@mixin btn-custom($color) {
  .btn-#{$color} {
    &.disabled,
    &:disabled {
      color: $white;
      background-color: $custom-gray-400;
      border-color: $custom-gray-400;
    }
  }

  .btn-outline-#{$color} {
    &.disabled,
    &:disabled {
      color: $custom-gray-400;
      background-color: transparent;
    }
  }
}

@mixin custom-alert-variant($background, $border, $color) {
  @include gradient-bg($background);
  color: $white;
  border-color: $border;

  hr {
    border-top-color: darken($border, 5%);
  }

  .alert-link {
    color: darken($color, 10%);
  }
}
