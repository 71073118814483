@import '~bootstrap/scss/functions';
@import 'variables';
@import '~bootstrap/scss/mixins';
@import 'mixins';
// @import '~bootstrap/scss/root';
@import 'root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
// @import '~bootstrap/scss/images';
// @import '~bootstrap/scss/code';
@import '~bootstrap/scss/grid';
// @import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
// @import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/nav';
@import 'custom-nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
// @import '~bootstrap/scss/breadcrumb';
// @import '~bootstrap/scss/pagination';
// @import '~bootstrap/scss/badge';
// @import '~bootstrap/scss/jumbotron';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
// @import '~bootstrap/scss/media';
// @import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
// @import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/modal';
// @import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
// @import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/utilities';
// @import '~bootstrap/scss/print';

@import 'header';

html,
body,
#root {
    height: 100%;
}

.btn-lg,
.btn-sm {
    &.disabled,
    &:disabled {
        background-color: gray('400');
        border-color: gray('400');
    }
}

@each $color, $value in $theme-colors {
    @include btn-custom($color);
}

.pt-base {
    padding-top: 6vw;
}

label {
    color: gray('500');
    font-family: Lato;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 20px;
}

.form-control {
    &:focus {
        box-shadow: none;
        border-color: theme-color('accent');
    }
}

// Alert
.alert {
    top: 20px;
    position: absolute;
    z-index: 9;

    svg {
        vertical-align: top;
    }
}

.form-errors {
    background-color: color('lighter-red');

    path {
        fill: theme-color('danger');
    }
    rect {
        fill: color('lighter-red');
    }
}

@each $color, $value in $theme-colors {
    .alert-#{$color} {
        @include custom-alert-variant($value, $value, $white);
    }
}

// @each $color, $value in $colors {
//   .alert-#{$color} {
//     // @include custom-alert-variant($value, $value, $white);
//     @include custom-alert-variant(theme-color-level($color, $alert-bg-level), theme-color-level($color, $alert-border-level), theme-color-level($color, $alert-color-level));
//   }
// }

input::placeholder {
  color: $custom-gray-300;
  font-weight: 300;
}

input[type=date]:not(.bol-date) {
  color: $custom-gray-500;
  padding: 1.3rem 1.2rem;
}

input[name=middle_initial] {
  width: 25%;
}

.discount-checkmark.custom-checkbox {
    .custom-control-label {
        &::before {
            height: 25px;
            width: 25px;
            top: 0;
        }

        &::after {
            height: 25px;
            width: 25px;
            top: 0;
        }

        .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
            // color: theme-color('success');
            // background-color: theme-color('success');
            // border: theme-color('success');
        }
    }

    .custom-control-input:checked ~ .custom-control-label::after {
        background-color: theme-color('success');
        border-radius: 3px;
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 8 8'><path fill='white' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>");
    }
}

.bg-lighter {
    background-color: #f9f9f9;
}

.flex-1 {
    flex: 1 1 0px;
}

.flex-none {
    flex: none !important;
}

.w-60 {
    width: 60%;
}

.w-sm-60 {
    @include media-breakpoint-up(sm) {
        width: 60%;
    }
}

.w-40 {
    width: 40%;
}

.w-sm-40 {
    @include media-breakpoint-up(sm) {
        width: 40%;
    }
}

.w-100 {
    width: 100%;
}

.w-sm-75 {
    @include media-breakpoint-up(sm) {
        width: 75%;
    }
}

.w-sm-50 {
    @include media-breakpoint-up(sm) {
        width: 50%;
    }
}

.text-medium-dark {
    color: $custom-gray-400;
}

.leading-none {
    line-height: 1;
}

.spinner-screen-wrapper {
    width: 100%;
    height: 300px;
    margin: 0 auto;
    top: 50%;
    margin-top: -100px;
    position: absolute;
    padding: 30px;

    @include media-breakpoint-up(sm) {
        height: 300px;
        width: 500px;
        margin-top: -150px;
        left: 50%;
        margin-left: -250px;
        padding: 0;
    }
}

.width-100 {
    width: 100%;
}

.h-100 {
  height: 100%;
}

.hide {
    display: none;
}

//placeholder styles TO DO: move into its own file
.payment-card {
  &--bordered {
    border: 2px solid theme-color('primary');
  }

  .title {
    font-size: 20px;
    color: var(--gray-600);
    font-weight: 700;
  }

  small {
    font-size: 1rem;
    font-weight: 400;
    color: var(--gray-400);
  }
}

.payment-tabs {
  .nav-link.active {
    border-bottom: 3px solid theme-color('primary');
  }
}

.text-horizontal-line {
  color: var(--gray-600);

  &::after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid var(--gray-200);
    margin: auto;
    margin-left: 1rem;
  }
}

.bs-popover-bottom > .arrow::before {
    border-bottom-color: #fff;
}

// override 

.image-scale {
  width: 100%; 
  height: auto;
}
// language selector

#langToolTip {
  z-index: 200;
  padding: 0 15px 0px;
  height: 98px;
  width: 192px;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(56,61,59,0.2), 0 10px 15px 5px rgba(56,61,59,0.15);
  text-align: left;
  border-style: none;
  border-width: 0;
  .langSelectForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  
  .form-check {
    margin: 10px 0;
  }
  .form-check-label {
    margin-left: 15px;
    color: $custom-gray-500;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
  }

  input {
    width: 20px;
    height: 20px;
  }
}
}
.errorClass{
    color: red !important;
    border-color: red !important;
}