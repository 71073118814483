@import "src/styles/_variables.scss";
@import '~bootstrap/scss/mixins/breakpoints';

.container-rate-overview {
  padding-bottom: 3rem;
  padding-top: 1rem;

  h1.h1-lg {
    font-size: calc(25px + 1.75vw);
  }

  .rater-navigation {
    max-width: 1600px;
    margin: 0 auto 2rem;

    .btn > svg {
      margin-top: -2px;
      margin-right: 8px;
      vertical-align: middle;
    }
  }
}

.container-rate-details {
  background-color: #f9f9f9;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;

  @include media-breakpoint-up(lg) {
    padding-bottom: 5rem;
    padding-top: 5rem;
  }
}

.rate-card-tabs {
  a {
    font-weight: 700;
  }
}

.container-rate-overview,
.container-rate-details {
  &__inner {
    max-width: 1100px;
  }
}

.recommended {
  border-radius: 4px 4px 0 0;
  background-color: #6078ff;
  content: "";
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 1rem 0;
  height: 34px;
  margin-top: -32px;

  span {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 4px;
    left: 0;
    color: #f9f9f9;
    font-size: 1rem;
  }
}
