.modal-lg {
	max-width: 650px;
}

.modal-body {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	p {
		margin: 0px;
		max-width: 500px;
	}
}

.envelope-oval {
  width: 70px;
  background-color: rgba(241, 100, 34, 0.27);
  padding: 1.25rem 1rem;
}
